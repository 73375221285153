var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen container mx-auto min-contents" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-12 gap-6" },
        [
          _c(
            "ValidationObserver",
            {
              ref: "resetPass",
              staticClass:
                "col-span-8 col-start-3 bg-white rounded-lg overflow-hidden border border-gray-400 p-4 mt-20",
              attrs: { tag: "div" },
            },
            [
              _c("p", { staticClass: "mb-6 text-justify" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.users.reset_password.explanation"))
                ),
              ]),
              _c("fl-labeled-input", {
                staticClass: "mb-6",
                attrs: {
                  fieldId: "email",
                  label: _vm.$t("commons.fields.mail_address"),
                  rules: { required: true, email: true },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c("fl-labeled-input", {
                staticClass: "mb-6",
                attrs: {
                  fieldId: "password",
                  label: _vm.$t("commons.fields.password"),
                  rules: { required: true, min: 8 },
                  type: "password",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _c(
                "button",
                {
                  staticClass:
                    "px-2 py-2 w-full mx-auto text-center bg-base-yellow font-bold",
                  on: { click: _vm.resetPassword },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("pages.users.reset_password.reset_password")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("NoticeLinkModal", { attrs: { modalName: "complete" } }, [
        _c("div", { staticClass: "text-center text-lg" }, [
          _vm.resultMessage
            ? _c("h3", { staticClass: "text-center mb-4 font-bold" }, [
                _vm._v(_vm._s(_vm.resultMessage)),
              ])
            : _vm._e(),
          _vm.resultMessageDetail
            ? _c("p", { staticClass: "text-center mb-6 text-sm" }, [
                _vm._v(_vm._s(_vm.resultMessageDetail)),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mx-auto" },
            [
              _vm.failedResetPass
                ? _c(
                    "button",
                    {
                      staticClass:
                        "px-2 py-2 w-full h-full text-center bg-base-yellow font-bold",
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("complete")
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  )
                : _c(
                    "router-link",
                    {
                      staticClass:
                        "px-2 py-2 w-1/2 text-center bg-base-yellow font-bold",
                      attrs: { to: "/" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "px-2 py-2 w-full text-center bg-base-yellow font-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.users.reset_password.redirect_top"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }